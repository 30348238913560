import React, { ChangeEvent } from 'react';
import { Theme, useMediaQuery } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import AlertTitle from '@mui/material/AlertTitle';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { AudienceType } from '../../../../../models/FormProps';
import InstructionsSection from './InstructionsSection';
import PreviewSection from './PreviewSection';
import { TableData } from './AutosaveContainer';
import PersonalisationDataTable from './PersonalisationDataTable';

interface Props {
  audienceType: AudienceType;
  viewOnly: boolean;
  tableData: TableData | null;
  hasUserDefinedHeaders: boolean;
  lastUploaded: Date | null;
  downloadPersonalisedFieldsUrl: string;
  handlePersonalisedFieldsUpload: (event: ChangeEvent<HTMLInputElement>) => void;
  removeAudienceMetadata: () => void;
  errors: Array<string>;
}

export default function PersonaliseAudience(props: Props) {
  const { audienceType, viewOnly, tableData, hasUserDefinedHeaders, lastUploaded, errors, ...personalisationHandlers } = props;
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  return (
    <>
      <InstructionsSection audienceType={audienceType} />
      <PreviewSection isDisabled={viewOnly} hasUserDefinedHeaders={hasUserDefinedHeaders} lastUploaded={lastUploaded} {...personalisationHandlers}>
        {errors.length > 0 && (
          <Box sx={isMobileOrTablet ? { paddingRight: 2 } : undefined}>
            <Alert severity="error">
              <AlertTitle sx={{ fontWeight: 700, lineHeight: '1.5rem', letterSpacing: '0.5px' }}>Please fix the following error(s) and try again</AlertTitle>
              <List sx={{ padding: 0, listStyleType: 'disc', paddingLeft: 2 }}>
                {errors.map(e => <ListItem key={e} sx={{ padding: 0, display: 'list-item' }}>{e}</ListItem>)}
              </List>
            </Alert>
          </Box>
        )}
        {renderPersonalisationDataTable()}
      </PreviewSection>
    </>
  );

  function renderPersonalisationDataTable() {
    if (tableData === null) {
      return <CircularProgress className="circular" />;
    }

    return <PersonalisationDataTable audienceType={audienceType} tableData={tableData} hasUserDefinedHeaders={hasUserDefinedHeaders} />;
  }
}
