import React from 'react';
import AppProvider from 'components/AppProvider';
import Footer from 'components/pages/common/Footer';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormProps, { FormAnswer, QuestionOptionsResponsesByQnID, LimitedResponsesErrorsByQnID } from '../../../models/FormProps';
import { UserMetadata } from '../../../form_elements/BaseElement';
import Content from './Content';
import TopBar from '../../../TopBar';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;
  form: FormProps;
  userMetadata: UserMetadata;
  answers: Array<FormAnswer>;
  questionOptionsResponsesByQnID?: QuestionOptionsResponsesByQnID;
  limitedResponsesErrors?: LimitedResponsesErrorsByQnID;
  formSubmissionError: string | null;
}

export default function Page(props: Props): React.ReactElement {
  return (
    <AppProvider>
      <Stack direction="column" spacing={15} justifyContent="space-between">
        <Box>
          <TopBar {...props} />
          <Content {...props} />
        </Box>
      </Stack>
      <Footer />
    </AppProvider>
  );
}
