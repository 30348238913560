import { Editor, IAllProps } from '@tinymce/tinymce-react';
import React, { useEffect, useState } from 'react';
import { getLocalStorageItem, LOCALSTORAGE_KEYS, setLocalStorageItem } from 'components/utils/local_storage';
import CustomTooltipAsPopover from 'components/pages/common/CustomTooltipAsPopover';
import { isEmpty } from '../../../../../utils/equality';
import escapeHtml from '../../../../../utils/escape_html';

export interface RichTextEditorProps {
  elementID ?: string;
  className?: string;
  value: string;
  readOnly?: boolean;
  metadataHeaders: Array<string>;
  onAddMetadata?: () => void;
  hasAdditionalMetadata: boolean;
  onFocus?: IAllProps['onFocus'] & IAllProps['onInit'];
  onChange?: (content: string) => void;
  configs?: string;
}

const defaultConfigs = 'bold italic underline | indentgroup | metadataButton | undo redo removeformat';

interface EditorMenuItemButton {
  type: 'menuitem',
  text: string,
  onAction: () => void
}

interface EditorMenuItemSeparator {
  type: 'separator'
}

type EditorMenuItemUnion = EditorMenuItemButton | EditorMenuItemSeparator ;

export default function RichTextEditor(props: RichTextEditorProps): React.ReactElement {
  const { elementID, className, readOnly, metadataHeaders, onAddMetadata, hasAdditionalMetadata, onFocus, onChange, configs } = props;

  const [initialValue, setInitialValue] = useState(props.value);

  // For some reason, if we give `value` directly to `<Editor>` and insert a metadata element,
  // the cursor appears before the newly inserted metadata element or sometimes the metadata
  // gets insert right at the start. Reading from a local state value here seems to resolve this
  // problem, which is possibly some rendering race-condition where it takes a long time for
  // `value` to be propagated down after a change has been made.
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setInitialValue(props.value);
    setValue(props.value);
  }, [elementID]);

  // The TinyMCE editor controls the menu item rendered. This is a hack to find the element
  // and attach a tooltip to it.
  const [toolTipAnchorPos, setTooltipAnchorPos] = useState<null | {
    left: number,
    top: number
  }>(null);
  const [isEditorFirstFocused, setIsEditorFirstFocused] = useState<boolean>(false);
  useEffect(() => {
    if (readOnly || !isEditorFirstFocused || isTooltipAlreadyShown() || !hasAdditionalMetadata) {
      // keep function returns consistent in useEffect
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }

    function findAndSetElement() {
      const element = document.querySelector<HTMLElement>('.tox-tbtn[title="Personalised Fields"]');
      if (!element) {
        return;
      }

      const rect = element.getBoundingClientRect();
      setTooltipAnchorPos({
        top: rect.bottom,
        left: (rect.left + rect.right) / 2
      });
    }

    findAndSetElement();
    window.addEventListener('resize', findAndSetElement);
    return () => window.removeEventListener('resize', findAndSetElement);
  }, [isEditorFirstFocused, readOnly]);

  function onTooltipClosed(): void {
    setPersonalisedFieldTooltipAsShown();
    setTooltipAnchorPos(null);
  }

  return (
    <>
      <div className={`rich-text-editor ${className}`}>
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          initialValue={initialValue}
          value={value}
          disabled={readOnly}
          inline
          // The editor is set to auto-focus on initialisation, but for some reason the editor is in a weird state
          // when the `onFocus` callback is called (e.g. `editor.hasFocus()` is false, and modifying the selection doesn't work).
          // Hence, we explicitly call `onFocus` again after it's fully initialised to ensure it works as expected.
          onInit={(event, editor) => {
            if (onFocus === undefined) return;

            onFocus(event, editor);
            setIsEditorFirstFocused(true);
          }}
          onFocus={onFocus}
          onEditorChange={(newValue, _editor) => {
            setValue(newValue);
            onChange(newValue);
          }}
          init={{
            auto_focus: true,
            height: '100%',
            menubar: false,
            statusbar: false,
            plugins: 'paste lists autolink noneditable',
            toolbar: isEmpty(configs) ? defaultConfigs : configs,
            toolbar_persist: true,
            toolbar_groups: {
              indentgroup: {
                icon: 'unordered-list',
                tooltip: 'Formatting',
                items: 'bullist numlist outdent indent'
              }
            },
            custom_elements: '~allears-metadata',
            extended_valid_elements: 'allears-metadata[class]',
            setup(editor) {
              editor.ui.registry.addMenuButton('metadataButton', {
                icon: 'user',
                tooltip: 'Personalised Fields',
                fetch(callback) {
                  if (metadataHeaders.length === 0) {
                    callback([{
                      type: 'menuitem' as const,
                      text: 'Personalised fields are not supported for your form type'
                    }]);

                    return;
                  }

                  const items: Array<EditorMenuItemUnion> = [];

                  if (onAddMetadata) {
                    items.push(
                      {
                        type: 'menuitem',
                        text: 'Add more fields',
                        onAction: onAddMetadata
                      }
                    );
                    items.push({ type: 'separator' });
                  }

                  items.push(...metadataHeaders.map(h => ({
                    type: 'menuitem' as const,
                    text: h,
                    onAction() {
                      const html = `<allears-metadata class="mceNonEditable">@${escapeHtml(h)}</allears-metadata> `;
                      editor.execCommand('mceInsertContent', false, html);
                    }
                  })));

                  callback(items);
                }
              });
            }
          }}
        />
      </div>
      <PersonalisedFieldTooltip anchorPosition={toolTipAnchorPos} onClose={onTooltipClosed} />
    </>
  );
}

interface PersonalisedFieldTooltipProps {
  anchorPosition: null | {
    top: number,
    left: number
  },
  onClose: () => void,
}

function PersonalisedFieldTooltip(props: PersonalisedFieldTooltipProps): React.ReactElement {
  const { anchorPosition, onClose } = props;

  const open = Boolean(anchorPosition);
  const id = open ? 'personalised-field-editor-tooltip' : undefined;

  return (
    <CustomTooltipAsPopover
      direction="up"
      popoverProps={{
        id,
        open,
        anchorReference: 'anchorPosition',
        anchorPosition,
        transformOrigin: {
          horizontal: 'center', vertical: 'top'
        },
        disablePortal: true
      }}
      onClose={onClose}
      title="Personalised fields"
      bodyText="Personalised fields (e.g. @name, @class) tells us
        where to include the personalised information from
        our data source or from the uploaded data file."
    />
  );
}

function isTooltipAlreadyShown(): boolean {
  const storedValue = getLocalStorageItem(LOCALSTORAGE_KEYS.EDITOR_PERSONALISED_FIELD_TOOLTIP);
  return !!storedValue && storedValue === '1';
}

function setPersonalisedFieldTooltipAsShown(): void {
  setLocalStorageItem(LOCALSTORAGE_KEYS.EDITOR_PERSONALISED_FIELD_TOOLTIP, '1');
}
