import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';

const paletteTheme = createTheme({
  palette: {
    primary: {
      light: '#3387FB',
      main: '#0054C8'
    },
    background: {
      default: '#F5F7FA'
    },
    text: {
      secondary: '#616161'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100, // changed from default 1200 to align with css media query width
      xl: 1536
    }
  }
});

const componentsThemeOptions: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          backgroundColor: paletteTheme.palette.common.white
        }
      }
    }
  }
};

const defaultTheme = createTheme(
  paletteTheme,
  componentsThemeOptions
);

export default defaultTheme;
