import React from 'react';
import TopBar from 'components/pages/sna/TopBar';
import { UserMetadata } from 'components/form_elements/BaseElement';
import FormProps, { FormAnswer, QuestionOptionsResponsesByQnID, LimitedResponsesErrorsByQnID } from 'components/models/FormProps';
import Content from './Content';
import SnaAppProvider from '../../SnaAppProvider';

interface Props {
  username: string;
  logOutUrl: string;
  homeUrl: string;
  form: FormProps;
  userMetadata: UserMetadata;
  answers: Array<FormAnswer>;
  questionOptionsResponsesByQnID?: QuestionOptionsResponsesByQnID;
  limitedResponsesErrors?: LimitedResponsesErrorsByQnID;
  formSubmissionError: string | null;
}

export default function Page(props: Props) {
  return (
    <SnaAppProvider>
      <TopBar {...props} />
      <Content {...props} />
    </SnaAppProvider>
  );
}
