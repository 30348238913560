export const enum LOCALSTORAGE_KEYS {
  EDITOR_PERSONALISED_FIELD_TOOLTIP = 'editor_personalised_field_tt',
  SELECT_AUDIENCE_NEXT_STAGE_TOOLTIP = 'select_audience_next_stage_tt'
}

export function setLocalStorageItem(key: LOCALSTORAGE_KEYS, value: string): void {
  localStorage.setItem(key, value);
}

export function getLocalStorageItem(key: LOCALSTORAGE_KEYS): string | null {
  return localStorage.getItem(key);
}

export function removeLocalStorageItem(key: LOCALSTORAGE_KEYS): void {
  localStorage.removeItem(key);
}

export function clearLocalStorage(): void {
  localStorage.clear();
}
