import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import blueGrey from '@mui/material/colors/blueGrey';
import Popover, { PopoverProps } from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { SxProps } from '@mui/material';

interface PersonalisedFieldTooltipProps {
  popoverProps: Omit<PopoverProps, 'onClose' | 'slotProps'>,
  direction: 'up' | 'down',
  onClose: () => void,
  title: string,
  bodyText: string,
  buttonText?: string
}

export default function CustomTooltipAsPopover(props: PersonalisedFieldTooltipProps): React.ReactElement {
  const { popoverProps, direction, title, bodyText, onClose, buttonText = 'GOT IT' } = props;

  const stackSx: SxProps = direction === 'up' ? { mt: 0.5 } : { mb: 0.5 };

  return (
    <Popover
      onClose={onClose}
      {...popoverProps}
      slotProps={{
        paper: {
          sx: { bgcolor: 'transparent', boxShadow: 'none' }
        }
      }}
    >
      <Stack sx={{ alignItems: 'center', ...stackSx }}>
        {direction === 'up' && <FilledArrow direction="up" />}
        <Stack sx={theme => ({
          bgcolor: blueGrey['900'],
          color: theme.palette.primary.contrastText,
          width: '280px',
          p: 2,
          gap: 2,
          justifyContent: 'center',
          borderRadius: '4px'
        })}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="body1" fontWeight={700}>
              {title}
            </Typography>
            <IconButton aria-label="close" onClick={onClose} color="inherit">
              <CloseIcon />
            </IconButton>
          </Box>
          <Typography variant="body2">
            {bodyText}
          </Typography>
          <Button
            variant="contained"
            size="small"
            onClick={onClose}
            sx={{ alignSelf: 'center' }}
          >
            {buttonText}
          </Button>
        </Stack>
        {direction === 'down' && <FilledArrow direction="down" />}
      </Stack>
    </Popover>
  );
}

interface FilledArrowProps {
  direction: 'up' | 'down'
}

function FilledArrow({ direction }: FilledArrowProps): React.ReactElement {
  return (
    <Box
      sx={{
        width: '24px',
        height: '24px',
        backgroundColor: blueGrey['900'],
        clipPath: direction === 'up'
          ? 'polygon(50% 0%, 100% 100%, 0% 100%)' // triangle pointing up
          : 'polygon(50% 100%, 100% 0%, 0% 0%)' // triangle pointing down
      }}
    />
  );
}
