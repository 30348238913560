import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { isEmpty } from 'components/utils/equality';
import CustomTooltipAsPopover from 'components/pages/common/CustomTooltipAsPopover';
import { getLocalStorageItem, LOCALSTORAGE_KEYS, setLocalStorageItem } from 'components/utils/local_storage';
import { Box, Container, Theme, useMediaQuery } from '@mui/material';
import { AudienceStage } from './Content';
import { AudienceProps, AudienceType } from '../../../../models/FormProps';

interface FooterProps {
  viewableAudience: AudienceProps;
  activeStep: AudienceStage;
  setActiveStepCallback: (stage: AudienceStage) => void;
}

export default function Footer({
  viewableAudience,
  activeStep,
  setActiveStepCallback
}: FooterProps): React.ReactElement {
  const audienceType = viewableAudience.type;
  const isMobileOrTablet = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));
  const { nextButtonText, buttonDisabled, nextAudienceStage } = displayNextButtonInfo();
  const [tooltipAnchorEl, setTooltipAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(tooltipAnchorEl);
  const id = open ? 'edit-audience-next-tooltip' : undefined;

  return (
    <Box position="fixed" bottom={0} width="100%" bgcolor="common.white" borderTop="0.5px solid #DBDBDB" zIndex={2000}>
      <Container>
        <Box
          display="flex"
          // eslint-disable-next-line no-nested-ternary
          justifyContent={activeStep !== AudienceStage.SelectAudienceType ? 'space-between' : isMobileOrTablet ? 'center' : 'flex-end'}
          marginY="12px"
        >
          {activeStep !== AudienceStage.SelectAudienceType && (
            <Button
              variant="outlined"
              size="large"
              onClick={() => setActiveStepCallback(activeStep - 1)}
              startIcon={<ChevronLeftIcon />}
            >
              Back
            </Button>
          )}
          <Button
            ref={setTooltipAnchorEl}
            variant="contained"
            size="large"
            onClick={() => setActiveStepCallback(nextAudienceStage)}
            disabled={buttonDisabled}
            endIcon={<ChevronRightIcon />}
          >
            {nextButtonText}
          </Button>
        </Box>
      </Container>
      {shouldShowTooltip() && (
        <CustomTooltipAsPopover
          direction="down"
          popoverProps={{
            id,
            open,
            anchorEl: tooltipAnchorEl,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center'
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'center'
            }
          }}
          title="Click to continue"
          bodyText="We've made some changes! Please click here to continue selecting your student or staff audience."
          onClose={onCloseTooltip}
        />
      )}
    </Box>
  );

  type NextButtonInfo = {
    nextButtonText: string;
    buttonDisabled: boolean;
    nextAudienceStage: AudienceStage;
  };

  function displayNextButtonInfo(): NextButtonInfo {
    switch (activeStep) {
      case AudienceStage.SelectAudienceType:
        if (audienceType === AudienceType.PUBLIC) {
          return {
            nextButtonText: 'Go To: Questions',
            buttonDisabled: false,
            nextAudienceStage: AudienceStage.GoToQuestions
          };
        } else if (audienceType === AudienceType.UNTARGETED_STAFF || audienceType === AudienceType.STAFF
          || audienceType === AudienceType.UNTARGETED_STUDENT || audienceType === AudienceType.STUDENT) {
          return {
            nextButtonText: isMobileOrTablet ? 'Next' : 'Next: Choose who can respond',
            buttonDisabled: false,
            nextAudienceStage: AudienceStage.SelectedTargetedOrUntargetedAudience
          };
        }
        break;

      case AudienceStage.SelectedTargetedOrUntargetedAudience:
        if (audienceType === AudienceType.UNTARGETED_STAFF || audienceType === AudienceType.UNTARGETED_STUDENT) {
          return {
            nextButtonText: 'Go To: Questions',
            buttonDisabled: false,
            nextAudienceStage: AudienceStage.GoToQuestions
          };
        } else if (audienceType === AudienceType.STAFF || audienceType === AudienceType.STUDENT) {
          return {
            nextButtonText: isMobileOrTablet ? 'Next' : 'Next: Select individuals or Groups',
            buttonDisabled: false,
            nextAudienceStage: AudienceStage.SelectIndividualsOrGroups
          };
        }
        break;

      case AudienceStage.SelectIndividualsOrGroups:
      case AudienceStage.PersonaliseFields:
        return {
          nextButtonText: 'Go To: Questions',
          buttonDisabled: (audienceType === AudienceType.STAFF || audienceType === AudienceType.STUDENT) && isEmpty(viewableAudience.entities),
          nextAudienceStage: AudienceStage.GoToQuestions
        };

      case AudienceStage.GoToQuestions:
        return {
          nextButtonText: 'Go To: Questions',
          buttonDisabled: false,
          nextAudienceStage: AudienceStage.GoToQuestions
        };

      default:
        throw new Error(`Invalid audience stage: ${activeStep}!`);
    }

    return {
      nextButtonText: '',
      buttonDisabled: false,
      nextAudienceStage: AudienceStage.SelectAudienceType
    };
  }

  function shouldShowTooltip(): boolean {
    if (audienceType === AudienceType.PUBLIC) {
      return false;
    }

    if (activeStep !== AudienceStage.SelectAudienceType) {
      return false;
    }

    return getLocalStorageItem(LOCALSTORAGE_KEYS.SELECT_AUDIENCE_NEXT_STAGE_TOOLTIP) !== '1';
  }

  function onCloseTooltip(): void {
    setTooltipAnchorEl(null);
    setTooltipAsShown();
  }
}

function setTooltipAsShown(): void {
  setLocalStorageItem(LOCALSTORAGE_KEYS.SELECT_AUDIENCE_NEXT_STAGE_TOOLTIP, '1');
}
